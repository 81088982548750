import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useApi } from "@/composable/useApi";
import { Organization } from "@/types/Organization";
import { request } from "@/stores/request";
import moment from "moment";

export const useOrganizationStore = defineStore('organizationStore', () => {
  const { getErrorMessage } = useApi();

  const organization = ref<Organization>({} as Organization);
  const userData = ref({
    dateFrom: moment().format('YYYY-MM-DD'),
    dateTo: moment().add(1, 'days').format('YYYY-MM-DD'),
    adults: 1,
    children: 0,
    infants: 0,
    animals: 0,
    name: '',
    email: '',
    phone: '',
    arrivalTime: '',
  });
  const screenType = ref('');
  const error = ref('');

  const duration = computed((): number => {
    const { dateTo, dateFrom } = userData.value;
    return moment(dateTo).diff(moment(dateFrom), 'days');
  });

  const guests = computed((): number => {
    const { adults, children, infants } = userData.value;
    return adults + children + infants;
  });

  const getOrganization = async (signature: string) => {
    try {
      organization.value = await request({ url: `organization/signature/${ signature }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    organization, error, userData, duration, guests, screenType,
    getOrganization,
  };
}, { persist: true });

import { useI18n } from "vue-i18n";
import { FormLocalizedValue } from "@/types/Form";
import { RoomType } from "@/types/RoomType";

export const useTranslation = () => {
  const i18n = useI18n();

  const getTranslation = (value: FormLocalizedValue) => {
    if (!hasTranslation(value ?? {})) {
      return '-';
    }

    const locale = i18n.locale.value;
    if (value?.[locale]) {
      return value[locale];
    } else {
      return Object.values(value)?.[0] ?? '-';
    }
  };

  const hasTranslation = (value: FormLocalizedValue | null | undefined) => {
    return Object.keys(value ?? {})?.length > 0 && Object.values(value ?? {})?.find(v => !!v);
  };

  const getDetails = (type: RoomType) => {
    return type.articles
      .map(a => {
        const texts = [getTranslation(a.text)];
        if (a.title && hasTranslation(a.title)) {
          texts.unshift(getTranslation(a.title))
        }
        return texts.join('<br/>');
      })
      .join('<br/>');
  };

  const getAmountVariant = (amount: number) => {
    const slicedAmount = parseInt(amount.toString().slice(-2));
    if (slicedAmount >= 11 && slicedAmount <= 14) {
      return 'five';
    }

    const remainder = amount % 10;
    if (remainder === 1) {
      return 'one';
    } else if (remainder >= 2 && remainder <= 4) {
      return 'two';
    } else {
      return 'five';
    }
  };

  const getCountableLocale = (locale: string, amount: number) => {
    return i18n.t(`${locale}.${getAmountVariant(amount)}`, { value: amount });
  };

  return { getTranslation, hasTranslation, getDetails, getAmountVariant, getCountableLocale };
};

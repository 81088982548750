<template>
  <div class="field" :class="`field--${color}`">
    <field-label v-if="label" :text="label" :is-required="isRequired" />

    <div class="field__wrapper">
      <div class="field__input" @click="() => !image?.name && onAttachClick()">
        <div v-if="image?.name" class="field__list-item">
          <p class="field__list-item__name">{{ image.name }}</p>
          <div class="field__list-item__delete" @click.stop="onDeleteClick"></div>
        </div>

        <p v-else-if="placeholder" class='field__placeholder'>{{ placeholder }}</p>
      </div>
      <div class="field__attach" @click="onAttachClick"></div>
    </div>
    <p v-if='hint' class='field__hint'>{{ hint }}</p>
    <input ref="input" type="file" hidden accept=".png, .jpg, .jpeg, .pdf" @change="setFiles" />
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from "vue";
import FieldLabel from "@/components/Form/FieldLabel.vue";

const props = defineProps({
  label: String,
  placeholder: String,
  hint: String,
  modelValue: {
    type: Object as PropType<File>,
    default: () => ({})
  },
  isRequired: Boolean,
  max: {
    type: Number,
    default: 100,
  },
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
});
const emits = defineEmits(['update:modelValue']);

const image = ref<File>(props.modelValue);
const input = ref();

const setFiles = () => {
  const files = input.value?.files;
  if (!files?.length) {
    return;
  }

  image.value = files[0];

  emits('update:modelValue', image.value);
};

const onAttachClick = () => input.value?.click();

const onDeleteClick = () => {
  image.value = {} as File;
  emits('update:modelValue', image.value);
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/components/form/form";

.field {
  &__wrapper {
    @extend .row-container;
    gap: 1rem;
    width: 100%;
  }

  &__label {
    margin: 1.5rem 0 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  &__attach {
    @extend .circle, .pointer;
    @include icon($upload, 3rem, 1.5rem);
    background-color: #FFE500CC;
  }

  &__list-item {
    @extend .row-container;
    gap: 0.25rem;
    min-height: 22px;

    &__name {
      @extend .text-ellipsis;
      @include font(18px, 120%, black);
    }

    &__delete {
      @extend .pointer;
      @include icon($close, 1.5rem);
    }
  }
}
</style>


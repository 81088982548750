import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { ref } from 'vue';
import { request } from "@/stores/request";
import { BookingSlot, CreateBookingDto } from "@/types/Booking";
import { useOrganizationStore } from "@/stores/organizationStore";

export const useBookingStore = defineStore('bookingStore', () => {
  const organizationStore = useOrganizationStore();
  const { getErrorMessage } = useApi();

  const bookedSlots = ref<BookingSlot>({} as BookingSlot);
  const bookingNumber = ref(0);
  const error = ref('');

  const getRoomTypeBookings = async (roomTypeId: number) => {
    try {
      bookedSlots.value = await request({
        url: `booking/room/type/${ roomTypeId }/${organizationStore.organization.id}`,
        method: 'get',
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getNextBookingNumber = async () => {
    try {
      bookingNumber.value = await request({
        url: `booking/next/number`,
        method: 'get',
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const createBooking = async (data: CreateBookingDto) => {
    try {
      await request({ url: `booking/web/${ organizationStore.organization.id }/create`, method: 'post', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    error, bookedSlots, bookingNumber,
    createBooking, getRoomTypeBookings, getNextBookingNumber,
  };
});

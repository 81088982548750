<template>
  <popup-container :style="position" :title="$t('guests.title')" @close="$emit('close')" @click="$emit('close')">
    <div class="list" @click.stop>
      <div v-for="item in ['adults', 'children', 'infants', 'animals']" :key="item" class="list__item">
        <div class="list__item-left">
          <p class="list__item-left__title">{{ $t(`guests.${ item }`) }}</p>
          <p class="list__item-left__subtitle">{{ $t(`guests.${ item }_age`) }}</p>
        </div>
        <div class="list__item-right">
          <div class="list__item-right__button minus" @click="userData[item]--"
               :class="{'disabled': userData[item] <= 0 || item === 'adults' && userData[item] <= 1}"></div>
          <p class="list__item-right__value">{{ userData[item] }}</p>
          <div class="list__item-right__button plus" :class="{'disabled': item === 'animals' && userData[item] >= 2}"
               @click="userData[item]++"></div>
        </div>
      </div>
    </div>
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { useOrganizationStore } from "@/stores/organizationStore";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

defineEmits(['close']);

const organizationStore = useOrganizationStore();
const { userData, screenType } = storeToRefs(organizationStore);

const position = ref('--left: 0; --right: 0;');

onMounted(() => {
  const el = document.getElementById('guests-el');
  if (el) {
    const maxLeft = document.documentElement.clientWidth - 24 * 16;
    const left = screenType.value === 'mobile' ? 16 : Math.min(el.offsetLeft + 12, maxLeft);
    position.value = `--left: ${ left }px; --right: unset;`;
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/components/guests";

:deep(.popup) {
  left: var(--left);
  right: var(--right);
  min-width: min(24rem, 100vw - 2rem);
  width: max-content !important;
  margin-top: 7rem;
  padding: 1rem !important;
  backdrop-filter: blur(15px);
}

:deep(.popup__close) {
  top: -0.25rem;
  right: -0.25rem;
  background-size: 1.5rem;
}

:deep(.popup__header) {
  margin-bottom: 1.75rem;
}

:deep(.popup__title) {
  font-size: 18px !important;
  font-weight: 500;
}

.mobile :deep(.popup) {
  //left: var(--left);
  //right: var(--right);
  min-width: min(23rem, 90vw);
  margin-top: 7rem;
}
</style>

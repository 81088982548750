import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from "@/composable/useApi";
import { request } from "@/stores/request";

export const useFileStore = defineStore('fileStore', () => {
  const { getErrorMessage } = useApi();

  const fileUrl = ref<string>('');
  const error = ref('');

  const uploadFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('files', file, encodeURIComponent(file.name));
      const result = await request({ url: `file/upload`, method: 'post', data: formData });
      fileUrl.value = result?.[0] ?? '';
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    fileUrl, error,
    uploadFile
  };
});

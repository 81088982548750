<template>
  <div class="panel">
    <div class="panel__content">
      <p class="title">{{ $t('room.rules_link') }} «{{ getTranslation(organization.name) }}»</p>
      <div v-html="text" class="panel__content-text"></div>
      <div class="panel__content-button" @click="back">{{ $t('back') }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useOrganizationStore } from "@/stores/organizationStore";
import { storeToRefs } from "pinia";
import { useTranslation } from "@/composable/useTranslation";
import { ref } from "vue";

const { back } = useRouter();
const organizationStore = useOrganizationStore();
const { organization } = storeToRefs(organizationStore);
const { getTranslation } = useTranslation();

const text = ref(`<p>Ознайомтесь будь ласка з правилами проживання та бронювання в Чорній скелі.</p>

<h3>Номер вважається заброньованим:</h3>
<ol>
<li>Коли гість написав своє прізвище та імʹя, вказав дату заїзду та виїзду; вказав контактний номер телефону та адресу електронної пошти;</li>
<li>Обрав категорію номеру, вказав кількість осіб в номері;</li>
<li>Вніс передоплату під час бронювання у розмірі вартості проживання за один день. Після оплати надіслав квитанцію як підтвердження оплати.</li>
</ol>

<h3>Правила проживання та відпочинку в ЧОРНІЙ СКЕЛІ:</h3>
<ol>
<li>Час реєстрації та заселення в готель починається з 15.00 год.</li>
<li>Час виїзду до 12.00 год.</li>
<li>При заселенні гість повинен засвідчити свою особу та оплатити вартість проживання до кінцевої дати виїзду. Після оплати гість отримує ключ від номеру. У разі передчасного виїзду з готелю кошти не повертаються.</li>
<li>Кошти не повертаються також якщо гість не приїхав з тих чи інших причин. Кошти зберігаються на депозиті і бронювання переноситься на інший термін але не довше як на три місяці. В разі якщо бронювання було перенесено а гість не приїхав то кошти не повертаються.</li>
<li>В день заїзду у вартість проживання включено доступ до басейнів (з 01 травня по 01 жовтня) та безлімітний доступ до СПА зони згідно графіку її роботи.</li>
<li>В день заїзду харчування не включено у вартість, тільки на наступний день проживання (сніданок). Обіди надаються і враховуються у вартість проживання тільки з 01 червня по 01 вересня у вигляді шведського столу. З 01 вересня по 31 травня у вартість враховуються тільки сніданки з розрахунку 250 гривень на дорослих і 150 гривень для дітей віком до 7-ми років.</li>
<li>В день виїзду гостям надається тільки сніданок. Якщо гість виїжджає раніше ніж подається сніданок в ресторані (до 9.00 год), то напередодні ввечері він може замовити сніданок із собою.</li>
<li>Ранній заїзд та пізній виїзд це платна послуга, що оплачується погодинно. Вартість 1 години – 100 грн.</li>
<li>Ранній заїзд та пізній виїзд можливий тільки якщо даний номер не заброньовано.</li>
</ol>

<p>З повагою,<br>
«Чорна Скеля»</p>
`);
</script>

<style lang="scss" scoped>
.panel__content {
  @extend .flex-col;
  gap: 1.5rem;
  height: 100%;

  &-text {
    @extend .custom-scroll, .flex-col;
    gap: 0.5rem;
    height: 100%;
    max-height: calc(100% - 7rem);
    @include font(18px, 120%, white, 500);
    overflow-y: auto;

    :deep(h3), :deep(ol) {
      margin: 0.5rem 0;
    }

    :deep(li) {
      margin: 0 0 0.75rem;
    }
  }

  &-button {
    @extend .pointer;
    min-height: 2.5rem;
    width: max-content;
    min-width: 22.5vw;
    margin: auto;
    @include font(16px, 2.5rem, black, 500, center);
    background-color: $primary;
    border-radius: 7px;
  }
}

.mobile .panel {
  margin-top: 2rem;

  &__content {
    gap: 1rem;

    &-text {
      @include font(16px, 120%, white, 500);
    }

    &-button {
      min-height: 56px;
      width: 100%;
      margin: 0 auto;
      font-size: 18px;
      line-height: 56px;
      font-weight: 600;
    }
  }
}
</style>

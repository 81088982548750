<template>
  <div class='field field--white' :class='inputClass' >
    <p v-if="label" class="filed__label">{{ label }}</p>
    <div class="field__input">
      <input v-model='value' ref='input' spellcheck='false' type='number' :placeholder="placeholder"
             :min="min" :max="max" @input='onInput' />
    </div>
    <p v-if='hint' class='field__hint'>{{ hint }}</p>
  </div>
</template>

<script lang='ts' setup>
import { computed, ref, watchEffect } from 'vue';

const props = defineProps({
  modelValue: Number,
  label: String,
  hint: String,
  placeholder: String,
  min: {
    type: Number,
    default: 1,
  },
  max: {
    type: Number,
    default: 1e7
  }
});
const emits = defineEmits(['update:modelValue']);

const value = ref(props.modelValue);
const error = ref('');

const inputClass = computed(() => {
  return [
    {
      'field--filled': Number.isInteger(value.value),
      'field--error': !!error.value,
    },
  ];
});

const onInput = () => {
  emits('update:modelValue', value.value);
};

watchEffect(() => value.value = props.modelValue ?? 0);
</script>

<style lang='scss' scoped>
@import "src/assets/styles/components/form/form";

input {
  background-color: transparent;
  font-family: Montserrat, "Segoe Print", sans-serif;
  @include font(18px, 120%, black);

  &, &:active, &:focus {
    outline: none;
    border: none;
  }
}
</style>

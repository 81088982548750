<template>
  <div class='field' :class="[{'field--error': isError}, `field--${color}`]">
    <field-label v-if="label" :text="label" :is-required="isRequired" />
    <div class='field__input' :class='{"field--filled": value.length}'>
      <input v-model='value' spellcheck='false' autocomplete="off" :placeholder="placeholder" @input='onInput' />
    </div>
    <p v-if="hint" class="field__hint">{{ hint }}</p>
    <p v-if="maxLength > 0" class="field__limit">{{ value.length }}/{{ maxLength }}</p>
  </div>
</template>

<script lang='ts' setup>
import { ref, watchEffect } from 'vue';
import FieldLabel from "@/components/Form/FieldLabel.vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  label: String,
  placeholder: String,
  hint: String,
  maxLength: {
    type: Number,
    default: null,
  },
  regexp: {
    type: RegExp,
    default: null
  },
  mask: String,
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
  isRequired: Boolean
});
const emits = defineEmits(['update:modelValue', 'enter']);

const value = ref(props.modelValue);
const isError = ref(false);

const onInput = (e: Event) => {
  if (props.maxLength > 0 && value.value.length > props.maxLength) {
    value.value = value.value.slice(0, props.maxLength);
  }

  if (props.mask) {
    let unmasked = '';
    for (let i = 0; i < Math.min(props.mask.length, value.value.length); i++) {
      if (props.mask[i] !== '.') {
        unmasked += value.value[i];
      }
    }

    let masked = '';

    if ((e as InputEvent).inputType === 'insertText') {
      for (let i = 0; i < unmasked.length; i++) {
        if (props.mask[masked.length] === 'N') {
          masked += unmasked[i];
        }

        if (props.mask[masked.length] && props.mask[masked.length] !== 'N') {
          masked += props.mask[masked.length]
        }
      }

      value.value = masked;
    }
  }

  if (props.regexp) {
    isError.value = !props.regexp.test(value.value);
  }

  emits('update:modelValue', value.value);
};

watchEffect(() => value.value = props.modelValue ?? '');
</script>

<style lang='scss' scoped>
@import "src/assets/styles/components/form/form";

.field {
  &--error {
    .field__input {
      border-color: $error !important;
    }
  }

  &__input {
    min-height: max-content;
    transition-duration: 0.2s;
    cursor: text;

    &:focus, &:has(input:focus), {
      border-color: $primary;
    }
  }
}

input {
  min-height: 0;
  height: 1.5rem;
  width: calc(100%);
  padding: 0;
  font-size: 18px;
  line-height: 1.5rem;
  font-family: Montserrat, sans-serif;
  resize: none;
  border: none;
  background-color: transparent;
}
</style>

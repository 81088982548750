import { ref } from 'vue';
import { OptionParams } from "@/types/Form";

export const useFormItem = () => {
  const isActive = ref(false);
  const item = ref<HTMLElement | null>(null);

  const getFloatingPosition = (itemsAmount: number, itemSize: number, isForceUp = false): OptionParams => {
    if (item.value && (!isActive.value || isForceUp)) {
      const { x, y, width, height } = item.value.getBoundingClientRect();
      const maxY = document.documentElement.clientHeight;
      let top = y + height;
      let direction = 'down';
      if (top + itemSize * itemsAmount > maxY || isForceUp) {
        top = y - (itemSize * itemsAmount) - 4;
        direction = 'up';
      }

      return {
        style: {
          width: `${width}px`,
          left: `${x}px`,
          top: `${top - (direction === 'down' ? 8 : -8)}px`
        },
        class: direction
      };
    } else {
      return {} as OptionParams;
    }
  };

  const hasClass = (el: HTMLElement | EventTarget | null, className: string) => {
    const classes = (el as HTMLElement)?.getAttribute('class') ?? '';
    return classes.includes(className) || className.includes(classes);
  };

  const hasClasses = (el: HTMLElement | EventTarget | null, classes: string[]) => {
    return classes.filter(item => hasClass(el, item)).length > 0;
  };

  const hide = (e: Event) => {
    if (e.type === 'scroll' && hasClass(e.target, 'select__options')) {
      return;
    }

    if (isActive.value) {
      isActive.value = false;
    }
  };

  const hideOnClick = (e: Event) => {
    const skip = ['select__options', 'select__value', 'select__bottom', 'select__top', 'list__item'];
    if (hasClasses(e.target, skip)) {
      return;
    }

    if (hasClasses(e.target, ['time-picker__up', 'time-picker__down'])) {
      return;
    }

    if ((e as PointerEvent).composedPath()?.find(el => el === item.value)) {
      return;
    }

    hide(e);
  };

  const setListeners = () => {
    window.addEventListener('resize', hide);
    document.documentElement.addEventListener('scroll', hide, true);
    document.documentElement.addEventListener('click', hideOnClick, true);
  };

  const deleteListeners = () => {
    window.removeEventListener('resize', hide);
    document.documentElement.removeEventListener('scroll', hide, true);
    document.documentElement.removeEventListener('click', hideOnClick, true);
  };

  return {
    item, isActive,
    getFloatingPosition, setListeners, deleteListeners
  };
};

import { error } from "@/locales/ru/error";

export const ru = {
  message_404: 'Страница не найдена',
  check_in: 'Заезд',
  check_out: 'Выезд',
  guests_amount: 'Количество гостей',
  guests_amount_mobile: 'Кол-во гостей',
  animals_amount: 'Количество животных',
  uah: 'грн',
  usd: '$',
  continue: 'Продолжить',
  choose_date: 'Выберите дату',
  choose_dates: 'Выберите период',
  available_date: 'Доступные',
  unavailable_date: 'Занятые',
  user_data_error: 'Ваши данные устарели. Пожалуйста, выполните поиск снова',
  try_again: 'Попробовать снова',
  file_error: 'Ошибка при загрузке файла',
  success_booking: 'Ваш запрос на бронирование был успешно получен. Сотрудник отеля обработает его в течение 24 часов. Пожалуйста, ожидайте письмо на указанный адрес электронной почты.',
  back: 'Назад',
  select: 'Выбрать',

  home: {
    title: 'Варианты размещения для вас',
    more: 'Больше предложений',
    details: 'Детали бронирования',
  },

  room: {
    rules_link: 'Правила проживания для посетителей отеля',
    total: 'Всего',
  },

  reserve: {
    form_title: 'Для бронирования номера заполните форму',
    name: 'Имя и Фамилия',
    email: 'Email',
    phone: 'Номер телефона',
    arrival: 'Ориентировочное время вашего приезда',
    details_title: 'Детали бронирования',
    room: 'Номер',
    dates: 'Даты',
    guests: 'Кол-во гостей',
    conditions_title: 'Условия оплаты бронирования',
    conditions_text: 'Для успешного бронирования необходимо внести предоплату в размере 50% от общей суммы. После осуществления платежа, пожалуйста, прикрепите фото или скриншот квитанции в поле ниже.',
    payment_title: 'Реквизиты для перевода',
    recipient: 'Получатель',
    edrpou: 'Код ЕДРПОУ',
    iban: 'IBAN',
    bank: 'Банк получателя',
    mfo: 'МФО банка',
    purpose: 'Назначение платежа',
    amount: 'Сумма',
    add_photo: 'Добавить файл (PNG, JPG, JPEG, PDF)',
    reserve: 'Забронировать',
    animals: 'Животные',
    yes: 'Да',
    no: 'Нет',
  },

  guests: {
    title: 'Выберите гостей:',
    adults: 'Взрослые',
    adults_age: 'Возраст от 13 лет',
    children: 'Дети',
    children_age: 'Возраст 2 - 12 лет',
    infants: 'Младенцы',
    infants_age: 'Возраст 0 - 2 лет',
    animals: 'Животные',
    animals_age: 'до 5 кг · 500 грн / день',
  },

  for_days: {
    one: 'за {value} день',
    two: 'за {value} дня',
    five: 'за {value} дней',
  },

  found_variants: {
    one: 'Найден {value} вариант',
    two: 'Найдено {value} варианта',
    five: 'Найдено {value} вариантов',
  },

  nights: {
    one: '{value} ночь',
    two: '{value} ночи',
    five: '{value} ночей',
  },

  adults: {
    one: '{value} Взрослый',
    two: '{value} Взрослых',
    five: '{value} Взрослых',
  },

  children: {
    one: '{value} Ребенок',
    two: '{value} Ребенка',
    five: '{value} Детей',
  },

  infants: {
    one: '{value} Младенец',
    two: '{value} Младенца',
    five: '{value} Младенцев',
  },

  people: {
    one: '{value} человек',
    two: '{value} человека',
    five: '{value} человек',
  },

  animals: {
    one: '{value} Животное',
    two: '{value} Животных',
    five: '{value} Животных',
  },

  error,
}

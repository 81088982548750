<template>
  <div class="panel">
    <div class="page__content">
      <p class="page__content-text">404</p>
      <p class="page__content-message">{{ $t('message_404') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.page {
  &__content {
    @extend .col-container;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: #0000001A;
    backdrop-filter: blur(12px);
    z-index: 1000;
    box-sizing: border-box;

    &-text {
      margin-left: 2rem;
      @include font(140px, 196px, $primary);
      font-weight: 700;
      text-shadow: -40px -33px 0 #{$primary}33;
      -webkit-text-fill-color: $primary;
    }

    &-message {
      margin-bottom: 3rem;
      @include font(24px, 34px, white, center);
      text-wrap: balance;
    }
  }
}

.base-button {
  background-color: white;

  :deep(.base-button__text) {
    color: $primary;
    font-weight: 500;
  }
}
</style>

<template>
  <div class="panel">
    <loader-panel v-if="isLoading" />
    <error-popup v-else-if="isDataError" :text="$t('user_data_error')" :button-text="$t('try_again')"
                 @close="push(getUrl(''))" />
    <div v-else class="panel__content">
      <div class="panel__left">
        <p class="title title--back" @click="back">{{ $t('reserve.form_title') }}:</p>
        <div class="panel__left-container">
          <form-input v-model="userData.name" :label="$t('reserve.name')" :is-required="true" />
          <form-input v-model="userData.email" :label="$t('reserve.email')" :regexp="emailRegex" :is-required="true" />
          <form-input v-model="userData.phone" :label="$t('reserve.phone')" :regexp="phoneRegex" :is-required="true" />
          <form-time v-model="userData.arrivalTime" :label="$t('reserve.arrival')" :selected="userData.arrivalTime" />

          <p class="title">{{ $t('reserve.details_title') }}:</p>
          <div class="panel__left-row">
            <p class="panel__left-row__label">{{ $t('reserve.room') }}</p>
            <p class="panel__left-row__value">{{ getTranslation(roomType.name) }}</p>
          </div>
          <div class="panel__left-row">
            <p class="panel__left-row__label">{{ $t('reserve.dates') }}</p>
            <p class="panel__left-row__value">
              {{ moment(userData.dateFrom).format('DD.MM.YYYY') }} - {{ moment(userData.dateTo).format('DD.MM.YYYY') }}
            </p>
          </div>
          <div class="panel__left-row">
            <p class="panel__left-row__label">{{ $t('reserve.guests') }}</p>
            <p class="panel__left-row__value">{{ getCountableLocale('people', guests) }}</p>
          </div>
          <div class="panel__left-row">
            <p class="panel__left-row__label">{{ $t('reserve.animals') }}</p>
            <p class="panel__left-row__value">{{ $t(`reserve.${ userData?.animals > 0 ? 'yes' : 'no' }`) }}</p>
          </div>
          <div class="panel__left-row">
            <p class="title">{{ $t('room.total') }}</p>
            <p class="panel__left-row__value">{{ price }} {{ $t(organization?.settings?.currency) }}</p>
          </div>
        </div>
      </div>
      <div class="panel__right">
        <p class="title">{{ $t('reserve.conditions_title') }}:</p>
        <p class="panel__right-text">{{ $t('reserve.conditions_text') }}</p>
        <p class="title">{{ $t('reserve.payment_title') }}:</p>
        <div v-for="item in paymentData" :key="item.label" class="panel__right-row">
          <p class="panel__right-row__label">{{ $t(`reserve.${ item.label }`) }}:</p>
          <p class="panel__right-row__value">{{ item.value }}</p>
        </div>

        <form-image v-model="photo" id="image" :is-required="true" :label="$t('reserve.add_photo')" />
        <base-button :text="$t('reserve.reserve')" :class="buttonState" @click="onReserveClick" />
      </div>
    </div>
  </div>

  <error-popup v-if="isFileError" :text="fileError ? fileError : $t('file_error')" :button-text="$t('try_again')"
               @close="isFileError = false" />
  <error-popup v-if="isBookingError" :text="error" :button-text="$t('try_again')" @close="isBookingError = false" />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useRoomTypeStore } from "@/stores/roomTypeStore";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import LoaderPanel from "@/components/LoaderPanel.vue";
import FormInput from "@/components/Form/FormInput.vue";
import { useRegex } from "@/composable/useRegex";
import FormTime from "@/components/Form/FormTime.vue";
import { useOrganizationStore } from "@/stores/organizationStore";
import { useTranslation } from "@/composable/useTranslation";
import FormImage from "@/components/Form/FormImage.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import moment from "moment";
import ErrorPopup from "@/components/Popup/ErrorPopup.vue";
import { useApi } from "@/composable/useApi";
import { useBookingStore } from "@/stores/bookingStore";
import { useI18n } from "vue-i18n";
import { useFileStore } from "@/stores/fileStore";
import { useRender } from "@/composable/useRender";

const i18n = useI18n();
const route = useRoute();
const { push, back } = useRouter();
const roomTypeStore = useRoomTypeStore();
const { roomType } = storeToRefs(roomTypeStore);
const organizationStore = useOrganizationStore();
const { organization, userData, duration, guests, screenType } = storeToRefs(organizationStore);
const bookingStore = useBookingStore();
const { error } = storeToRefs(bookingStore);
const fileStore = useFileStore();
const { fileUrl, error: fileError } = storeToRefs(fileStore);
const { emailRegex, phoneRegex } = useRegex();
const { getTranslation, getCountableLocale } = useTranslation();
const { checkData, getUrl } = useApi();
const { renderNotification } = useRender();

const photo = ref<File>({} as File);
const isLoading = ref(true);
const isDataError = ref(false);
const isFileError = ref(false);
const isBookingError = ref(false);

const paymentData = computed(() => [
  { label: 'recipient', value: 'ВАЦКО МАРИНА МИКОЛАЇВНА' },
  { label: 'edrpou', value: '2688309801' },
  { label: 'iban', value: 'UA793052990000026002013613634' },
  //{ label: 'bank', value: 'АТ "Ощадбанк"' },
  //{ label: 'mfo', value: '300465' },
  {
    label: 'purpose',
    value: `Передоплата за бронювання`
  },
  {
    label: 'amount',
    value: `${ prepayment.value } ${ i18n.t(organization.value?.settings?.currency) } (${ getCountableLocale('nights', 1) })`
  },
]);

const price = computed((): number => {
  let price = roomType.value.price * duration.value;
  if (userData.value?.animals > 0) {
    price += duration.value * 500;
  }
  return price;
});

const prepayment = computed((): number => Math.ceil(price.value / duration.value));

const buttonState = computed((): string => {
  const { dateFrom, dateTo, adults, name, email, phone } = userData.value;
  const isValid = !!(dateFrom && dateTo && name && email && phone) && adults > 0;
  return isValid && photo.value?.name ? '' : 'inactive';
  /*if (isValid) {
    return photo.value?.name ? '' : 'inactive';
  }
  return 'disabled';*/
});

const onReserveClick = async () => {
  if (buttonState.value === '') {
    isLoading.value = true;
    await fileStore.uploadFile(photo.value);
    if (!fileUrl.value || fileError.value) {
      isFileError.value = true;
    } else {
      await bookingStore.createBooking({
        roomTypeId: +route.params.roomTypeId,
        ...userData.value,
        photoUrl: fileUrl.value,
        paid: prepayment.value,
        price: price.value,
      });
      if (error.value) {
        isBookingError.value = true;
      } else {
        push(getUrl(''));
        setTimeout(() => renderNotification('success_booking', false, 10000), 0);
      }
    }
    isLoading.value = false;
  } else if (buttonState.value === 'inactive') {
    const emptyFields = Array.from(document.querySelectorAll('.panel__left .field__input:not(.select):not(.field--filled)') ?? []);
    if (emptyFields?.length) {
      emptyFields.map(i => i.classList.add('error'));
    }

    const el = document.querySelector('#image .field__input');
    el?.classList?.add('error');
    if (screenType.value === 'mobile') {
      if (!emptyFields.length) {
        el?.scrollIntoView({ behavior: 'smooth' });
      } else {
        emptyFields[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    setTimeout(() => {
      const errors = Array.from(document.getElementsByClassName('error'));
      errors.map(i => i.classList.remove('error'));
    }, 1500);
  }
}

onMounted(async () => {
  isDataError.value = checkData();
  if (!isDataError.value) {
    await roomTypeStore.getRoomType(+route.params.roomTypeId);
    await bookingStore.getNextBookingNumber();

    if (!userData.value.phone) {
      userData.value.phone = organization.value.settings?.countryCode ?? '';
    }
  }

  isLoading.value = false;
});
</script>

<style lang="scss" scoped>
.panel {
  &__content {
    flex-direction: row !important;
    gap: 3.75rem;
    width: 100%;
    height: 100%;
    overflow-y: visible;
  }

  &__left {
    @extend .flex-col;
    max-height: 100%;
    max-width: 37.5vw;

    &-container {
      @extend .flex-col, .custom-scroll;
      gap: 1.5rem;
      margin-top: 1rem;
      padding-right: 0.5rem;
      overflow-y: auto;
    }

    &-row {
      @extend .row-container;
      justify-content: space-between;
      margin-bottom: -0.5rem;

      &:last-child .panel__left-row__value {
        @include font(24px, 120%, white, 600);
      }

      &__label {
        @include font(16px, 120%, white, 400);
      }

      &__value {
        @include font(18px, 120%, white, 500);
      }
    }

    .field + .title {
      margin-top: 1rem;
    }
  }

  &__right {
    @extend .flex-col;
    gap: 1rem;
    position: relative;
    top: -4.5rem;
    flex: 1;
    height: calc(100% + 4.5rem);
    padding-left: 3.75rem;

    &:before {
      position: absolute;
      @include before(0, 100%);
      top: 0;
      left: 0;
      border-left: 1.5px solid #FFFFFFAA;
    }

    &-text {
      margin-bottom: 0.5rem;
      @include font(18px, 120%, white, 400);
    }

    &-row {
      @extend .row-container;
      justify-content: space-between;

      &__label {
        @include font(16px, 120%, white, 400);
      }

      &__value {
        max-width: 70%;
        @include font(18px, 120%, white, 600, right);
        text-wrap: balance;
        word-wrap: break-word;
      }
    }
  }
}

.base-button {
  max-width: 32.75vw;
  margin: auto 0 0 auto;
}

.mobile .panel {
  max-height: calc(100% - 6rem - 56px - 1rem);
  margin-top: 2rem;

  &__content {
    @extend .no-scrollbar;
    gap: 1rem;
    flex-direction: column !important;
    overflow-y: auto;
  }

  &__left {
    gap: 1rem;
    max-width: 100%;
    min-height: max-content;
    max-height: unset;
    overflow: unset;

    &-row {
      gap: 0.5rem;
      margin-bottom: 0;

      &__value {
        text-align: right;
      }
    }
  }

  &__right {
    top: 0;
    margin-top: 1rem;
    padding-left: 0;

    &:before {
      display: none;
    }

    .base-button {
      position: fixed;
      bottom: 1rem;
      max-width: calc(100% - 2rem);
    }
  }
}
</style>

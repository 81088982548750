import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { ref } from 'vue';
import { request } from "@/stores/request";
import { useOrganizationStore } from "@/stores/organizationStore";
import { RoomType } from "@/types/RoomType";

export const useRoomTypeStore = defineStore('roomTypeStore', () => {
  const organizationStore = useOrganizationStore();
  const { getErrorMessage } = useApi();

  const roomTypes = ref<RoomType[]>([]);
  const roomType = ref<RoomType>({} as RoomType);
  const error = ref('');

  const getRoomTypes = async () => {
    try {
      roomTypes.value = await request({ url: `room-type/org/${ organizationStore.organization.id }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getFilteredRoomTypes = async () => {
    try {
      const { dateFrom, dateTo, infants, children, adults} = organizationStore.userData;
      roomTypes.value = await request({
        url: `room-type/org/${ organizationStore.organization.id }`,
        method: 'post',
        data: {
          dateFrom, dateTo,
          guests: infants + children + adults,
        }
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getRoomType = async (id: number) => {
    try {
      roomType.value = await request({ url: `room-type/${ id }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    roomTypes, roomType, error,
    getRoomTypes, getRoomType, getFilteredRoomTypes
  };
});

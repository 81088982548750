import { h, render } from 'vue';
import { useI18n } from 'vue-i18n';
import NotificationPopup from "@/components/Popup/NotificationPopup.vue";

export const useRender = () => {
  const i18n = useI18n();

  const renderNotification = (text: string, isLocalized = false, durationMs = 3500) => {
    const container = document.createElement('div');
    render(h(NotificationPopup, { text: `${ isLocalized ? text : i18n.t(text) }`, durationMs }), container);
  };

  return { renderNotification };
};

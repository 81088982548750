import { error } from "@/locales/en/error";

export const en = {
  message_404: 'Page not found',
  check_in: 'Check-in',
  check_out: 'Check-out',
  guests_amount: 'Number of guests',
  guests_amount_mobile: 'Guests',
  animals_amount: 'Amount of animals',
  uah: 'uah',
  usd: '$',
  continue: 'Continue',
  choose_date: 'Choose date',
  choose_dates: 'Choose date period',
  available_date: 'Available',
  unavailable_date: 'Unavailable',
  user_data_error: 'Your data is expired. Please, search again',
  try_again: 'Try again',
  file_error: 'Error while uploading file',
  success_booking: 'Your booking request has been successfully received. A hotel staff member will process it within 24 hours. Please await an email that will be sent to the provided email address.',
  back: 'Back',
  select: 'Select',

  home: {
    title: 'Accommodation options for you',
    more: 'More offers',
    details: 'Booking details',
    not_found: 'На жаль, за вашим запитом нічого не знайдено. Спробуйте змінити дати відпочинку або кількість гостей, і повторіть пошук.',
  },

  room: {
    rules_link: 'Hotel visitor rules',
    total: 'Total',
  },

  reserve: {
    form_title: 'To reserve a room, fill out the form',
    name: 'First and Last Name',
    email: 'Email',
    phone: 'Phone number',
    arrival: 'Estimated time of arrival',
    details_title: 'Booking details',
    room: 'Room',
    dates: 'Dates',
    guests: 'Number of guests',
    conditions_title: 'Booking payment conditions',
    conditions_text: 'To successfully book a room, a prepayment of 50% of the total amount is required. After making the payment, please attach a photo or screenshot of the receipt in the field below.',
    payment_title: 'Payment details',
    recipient: 'Recipient',
    edrpou: 'EDRPOU code',
    iban: 'IBAN',
    bank: 'Recipient\'s bank',
    mfo: 'Bank MFO',
    purpose: 'Payment purpose',
    amount: 'Amount',
    add_photo: 'Add file (PNG, JPG, JPEG, PDF)',
    reserve: 'Reserve',
    animals: 'Animals',
    yes: 'Yes',
    no: 'No',
  },

  guests: {
    title: 'Select guests:',
    adults: 'Adults',
    adults_age: 'Age 13+',
    children: 'Children',
    children_age: 'Age 2 - 12',
    infants: 'Infants',
    infants_age: 'Age 0 - 2',
    animals: 'Animals',
    animals_age: 'up to 5 kg · 500 uah / day',
  },

  for_days: {
    one: 'in {value} day',
    two: 'in {value} days',
    five: 'in {value} days',
  },

  found_variants: {
    one: 'Found {value} option',
    two: 'Found {value} options',
    five: 'Found {value} options',
  },

  nights: {
    one: '{value} night',
    two: '{value} nights',
    five: '{value} nights',
  },

  adults: {
    one: '{value} Adult',
    two: '{value} Adults',
    five: '{value} Adults',
  },

  children: {
    one: '{value} Child',
    two: '{value} Children',
    five: '{value} Children',
  },

  infants: {
    one: '{value} Infant',
    two: '{value} Infants',
    five: '{value} Infants',
  },

  people: {
    one: '{value} Person',
    two: '{value} People',
    five: '{value} People',
  },

  animals: {
    one: '{value} Animal',
    two: '{value} Animals',
    five: '{value} Animals',
  },

  error,
};

<template>
  <div class='field' :class="`field--${color}`">
    <p v-if="label" class="field__label">{{ label }}</p>
    <div class='field__input select' ref='item' :class='selectClass' @click.stop='onClick'>
      <p v-if="value?.hours >= 0" class='select__value'>{{ modelValue }}</p>
      <p v-else-if="placeholder" class='field__placeholder'>{{ placeholder }}</p>
      <div class='select__arrow' :class="{'select__arrow--turned': isActive}"></div>
    </div>
    <p v-if="hint" class="field__hint">{{ hint }}</p>
    <transition :name="`show-${direction}`">
      <div v-if='isActive' class='select__options' v-bind='optionsParams'>
        <div class="select__left" @scroll.stop @wheel.stop="onHoursWheel" @touchstart="onTouchStart"
             @touchmove="onHoursTouchMove">
          <div class="select__top" @click.stop="hourOffset--" @touchstart="onArrowTouchStart('hour', 'up')"
               @touchend="onArrowTouchEnd" @mousedown="onArrowTouchStart('hour', 'up')"
               @mouseup="onArrowTouchEnd"></div>
          <div class="select__bottom" @click.stop="hourOffset++" @touchstart="onArrowTouchStart('hour', 'down')"
               @touchend="onArrowTouchEnd" @mousedown="onArrowTouchStart('hour', 'down')"
               @mouseup="onArrowTouchEnd"></div>
          <p v-for="n in [-2, -1, 0, 1, 2]" :key="n">{{ getHourValue(startHour + hourOffset + n) }}</p>
        </div>
        <div class="select__right" @scroll.stop @wheel.stop="onMinutesWheel" @touchstart="onTouchStart"
             @touchmove="onMinutesTouchMove">
          <div class="select__top" @click.stop="minuteOffset--" @touchstart="onArrowTouchStart('minute', 'up')"
               @touchend="onArrowTouchEnd" @mousedown="onArrowTouchStart('minute', 'up')"
               @mouseup="onArrowTouchEnd"></div>
          <div class="select__bottom" @click.stop="minuteOffset++" @touchstart="onArrowTouchStart('minute', 'up')"
               @touchend="onArrowTouchEnd" @mousedown="onArrowTouchStart('minute', 'down')"
               @mouseup="onArrowTouchEnd"></div>
          <p v-for="n in [-2, -1, 0, 1, 2]" :key="n">{{ getMinuteValue(startMinute + minuteOffset + n) }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang='ts' setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { OptionParams } from "@/types/Form";
import { useFormItem } from "@/composable/useFormItem";

const props = defineProps({
  label: String,
  placeholder: String,
  hint: String,
  modelValue: {
    type: String,
    default: ''
  },
  modelValueDate: {
    type: Date,
  },
  isRequired: Boolean,
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
});
const emits = defineEmits(['update:modelValue']);

const { isActive, item, getFloatingPosition, setListeners, deleteListeners } = useFormItem();

const direction = ref('down');
const optionsParams = ref<OptionParams>({} as OptionParams);
const hourOffset = ref(0);
const minuteOffset = ref(0);
const startHour = ref(0);
const startMinute = ref(0);
const startY = ref(0);
const startMs = ref(0);
const arrowInterval = ref(0);

const selectClass = computed((): { [key: string]: boolean } => {
  return {
    'select--active': isActive.value,
  };
});

const value = computed((): { hours: number; minutes: number; } | null => {
  if (props.modelValue) {
    return { hours: +props.modelValue.slice(0, 2), minutes: +props.modelValue.slice(-2) };
  } else {
    return null;
  }
});

const onClick = () => {
  optionsParams.value = getFloatingPosition(5, 34);
  direction.value = optionsParams.value?.class ? optionsParams.value.class : direction.value;
  isActive.value = !isActive.value;
};

const getTimeValue = (v: number, max: number) => {
  let value = v > max ? v - max : v;

  if (value < 0) {
    value = max + value % max;
  }

  if (value === max) {
    value = 0;
  }

  return `0${ value }`.slice(-2);
};

const getHourValue = (v: number) => getTimeValue(v, 24);

const getMinuteValue = (v: number) => getTimeValue(v, 60);

const onHoursWheel = (e: Event) => {
  if ((e as WheelEvent).deltaY > 0) {
    hourOffset.value = (hourOffset.value + 1) % 24;
  } else {
    hourOffset.value = hourOffset.value - 1;
  }
};

const onTouchStart = (e: Event) => startY.value = (e as TouchEvent).touches[0].clientY;

const onHoursTouchMove = (e: Event) => {
  const diff = (e as TouchEvent).touches[0].clientY - startY.value;
  if (diff < -25) {
    hourOffset.value = (hourOffset.value + 1) % 24;
    startY.value = (e as TouchEvent).touches[0].clientY;
  } else if (diff > 25) {
    hourOffset.value = hourOffset.value - 1;
    startY.value = (e as TouchEvent).touches[0].clientY;
  }
};

const onMinutesWheel = (e: Event) => {
  if ((e as WheelEvent).deltaY > 0) {
    minuteOffset.value = (minuteOffset.value + 1) % 60;
  } else {
    minuteOffset.value = minuteOffset.value - 1;
  }
};

const onMinutesTouchMove = (e: Event) => {
  const diff = (e as TouchEvent).touches[0].clientY - startY.value;
  if (diff < -25) {
    minuteOffset.value = (minuteOffset.value + 1) % 60;
    startY.value = (e as TouchEvent).touches[0].clientY;
  } else if (diff > 25) {
    minuteOffset.value = minuteOffset.value - 1;
    startY.value = (e as TouchEvent).touches[0].clientY;
  }
};

const onArrowTouchStart = (type: 'hour' | 'minute', direction: 'up' | 'down') => {
  startMs.value = Date.now();
  arrowInterval.value = setInterval(() => {
    if (type === 'hour') {
      hourOffset.value += direction === 'up' ? -1 : 1;
    } else {
      minuteOffset.value += direction === 'up' ? -1 : 1;
    }
  }, 100);
};

const onArrowTouchEnd = () => clearInterval(arrowInterval.value);

onMounted(() => {
  setListeners();

  const startDate = props.modelValue ?? '09:00';
  startHour.value = +startDate.slice(0, 2);
  startMinute.value = +startDate.slice(-2);
});

onUnmounted(deleteListeners);

watch([() => hourOffset.value, () => minuteOffset.value], () => {
  const hour = getHourValue(startHour.value + hourOffset.value);
  const minute = getMinuteValue(startMinute.value + minuteOffset.value);
  emits('update:modelValue', `${ hour }:${ minute }`);
});
</script>

<style lang='scss' scoped>
@import "@/assets/styles/components/form/select";

.field.disabled {
  opacity: 0.75;
  pointer-events: none;
}

.select {
  &__options {
    flex-direction: row !important;
    justify-content: center;
    padding: 2rem 0 1.5rem;
    backdrop-filter: blur(4px);

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      @include before(calc(100% - 1rem), 34px);
      margin: auto;
      border-radius: 5px;
      box-shadow: 0 4px 4px 0 #00000033;
    }
  }

  &__left, &__right {
    @extend .flex-col;
    justify-content: flex-end;
    flex: 1;
    position: relative;

    p {
      width: 4rem;
      @include font(20px, 34px, black, 500, center);
      z-index: 100;

      &:nth-child(3), &:nth-child(7) {
        color: #0000008C;
      }

      &:nth-child(4), &:nth-child(6) {
        color: #000000B2;
      }
    }
  }

  &__left {
    align-items: flex-end;
  }

  &__top, &__bottom {
    position: absolute;
    width: 4rem;
    height: 2rem;
    @include svgBg($select-arrow, 1.5rem);
    background-position-x: calc(100% - 1.25rem);
    z-index: 100;
  }

  &__top {
    top: -1.75rem;
    transform: rotate(0.5turn);
  }

  &__bottom {
    bottom: -1.5rem;
  }
}
</style>

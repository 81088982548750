import { error } from "@/locales/ua/error";

export const ua = {
  message_404: 'Сторінку не знайдено',
  check_in: 'Прибуття',
  check_out: 'Виїзд',
  guests_amount: 'Кількість гостей',
  guests_amount_mobile: 'Кіл-ть гостей',
  animals_amount: 'Кількість тварин',
  uah: 'грн',
  usd: '$',
  continue: 'Продовжити',
  choose_date: 'Оберіть дату',
  choose_dates: 'Оберіть період',
  available_date: 'Доступні',
  unavailable_date: 'Зайняті',
  user_data_error: 'Ваші дані застаріли. Будь ласка, виконайте пошук знову',
  try_again: 'Спробуйте ще раз',
  file_error: 'Помилка під час завантаження файлу',
  success_booking: 'Запит на Ваше бронювання було успішно сформовано. Співробітник готелю обробить його протягом доби. Очікуйте на лист, що надійде на вказану електронну пошту.',
  back: 'Назад',
  select: 'Обрати',

  home: {
    title: 'Варіанти розміщення для вас',
    more: 'Більше пропозицій',
    details: 'Деталі бронювання',
  },

  room: {
    rules_link: 'Правила проживання для відвідувачів готелю',
    total: 'Всього',
  },

  reserve: {
    form_title: 'Для бронювання номеру, заповніть форму',
    name: 'Ім\'я та Прізвище',
    email: 'Email',
    phone: 'Номер телефону',
    arrival: 'Орієнтовний час Вашого приїзду',
    details_title: 'Деталі бронювання',
    room: 'Номер',
    dates: 'Дати',
    guests: 'Кіл-ть гостей',
    conditions_title: 'Умови оплати бронювання',
    conditions_text: 'Для успішного бронювання необхідно внести передоплату у розмірі 50% від загальної суми. Після здійснення платежу, будь ласка, прикріпіть фото або скріншот квитанції у полі нижче.',
    payment_title: 'Реквізити для переказу',
    recipient: 'Отримувач',
    edrpou: 'Код ЄДРПОУ',
    iban: 'IBAN',
    bank: 'Банк отримувача',
    mfo: 'МФО банку',
    purpose: 'Призначення платежу',
    amount: 'Сума',
    add_photo: 'Додати файл (PNG, JPG, JPEG, PDF)',
    reserve: 'Забронювати',
    animals: 'Тварини',
    yes: 'Так',
    no: 'Ні',
  },

  guests: {
    title: 'Оберіть гостей:',
    adults: 'Дорослі',
    adults_age: 'Вік від 13р.',
    children: 'Діти',
    children_age: 'Вік 2 - 12р.',
    infants: 'Немовлята',
    infants_age: 'Вік 0 - 2р.',
    animals: 'Тварини',
    animals_age: 'до 5 кг · 500 грн / доба',
  },

  for_days: {
    one: 'за {value} день',
    two: 'за {value} дні',
    five: 'за {value} днів',
  },

  found_variants: {
    one: 'Знайдено {value} варіант',
    two: 'Знайдено {value} варіанти',
    five: 'Знайдено {value} варіантів',
  },

  nights: {
    one: '{value} ніч',
    two: '{value} ночі',
    five: '{value} ночей',
  },

  adults: {
    one: '{value} Дорослий',
    two: '{value} Дорослих',
    five: '{value} Дорослих',
  },

  children: {
    one: '{value} Дитина',
    two: '{value} Дитини',
    five: '{value} Дітей',
  },

  infants: {
    one: '{value} Немовля',
    two: '{value} Немовля',
    five: '{value} Немовлят',
  },

  people: {
    one: '{value} Особа',
    two: '{value} Особи',
    five: '{value} Осіб',
  },

  animals: {
    one: '{value} Тварина',
    two: '{value} Тварини',
    five: '{value} Тварин',
  },

  error,
};

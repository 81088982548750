export const error = {
  ERROR_ALREADY_LOGGED_IN: 'Użytkownik jest już zalogowany',
  ERROR_DO_NOT_HAVE_ACCESS: 'Nie masz dostępu do tej akcji',
  ERROR_INCORRECT_AUTH_DATA: 'Nieprawidłowy login lub hasło',
  ERROR_INCORRECT_OLD_PASSWORD: 'Nieprawidłowe stare hasło',
  ERROR_UNAUTHORIZED: 'Użytkownik niezalogowany',
  ERROR_USER_DOES_NOT_EXIST: 'Użytkownik nie istnieje',
  ERROR_BOT_ALREADY_EXISTS: 'Taki bot już istnieje',
  ERROR_BOT_DOES_NOT_EXIST: 'Taki bot nie istnieje',
  ERROR_USER_IS_NOT_SUBSCRIBED: 'Ten użytkownik nie jest subskrybentem bota',
  ERROR_LINK_NOT_FOUND: 'Link nie został znaleziony',
  ERROR_ORGANIZATION_DOES_NOT_EXIST: 'Taka organizacja nie istnieje',
  ERROR_REQUEST_NOT_FOUND: 'Zadanie nie zostało znalezione',
  ERROR_TELEGRAM: 'Nie można przetworzyć wiadomości. Błąd Telegramu.',
  ERROR_USER_ALREADY_EXISTS: 'Taki użytkownik już istnieje',
  ERROR_NO_EMAIL: 'Użytkownik nie ma adresu e-mail. Nie można wysłać wiadomości',
  ERROR_QUESTIONNAIRE_NOT_FOUND: 'Błąd podczas pobierania listy opinii',
  ERROR_MAIL_TRANSLATION: 'Nie udało się przetłumaczyć treści wiadomości',
  ERROR_INACTIVE_ORGANIZATION: 'Aplikacja nie jest już obsługiwana dla tej organizacji',
  ERROR_NO_FREE_ROOMS: 'Brak wolnych pokoi',
};

<template>
  <div class="images">
    <div class="images__container">
      <transition :name="`slide-${transition}`">
        <async-image :src="imageSrc[currentImage]" class="images__item" :key="currentImage" />
      </transition>
    </div>

    <div v-if="images.length > 1" class="images__buttons">
      <div class="images__buttons-item prev" @click="onPrevClick"></div>
      <div class="images__buttons-item next" @click="onNextClick"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, PropType, ref } from "vue";
import AsyncImage from "@/components/Image/AsyncImage.vue";
import { useApi } from "@/composable/useApi";

const props = defineProps({
  images: {
    type: Array as PropType<string[]>,
    default: () => []
  }
});

const { getApiUrl } = useApi();

const imageSrc = ref<{ [key: number]: string }>({});
const currentImage = ref(0);
const transition = ref('left');

const onPrevClick = () => {
  transition.value = 'right';
  currentImage.value = currentImage.value > 0 ? currentImage.value - 1 : props.images.length - 1;
};

const onNextClick = () => {
  transition.value = 'left';
  currentImage.value = currentImage.value < props.images.length - 1 ? currentImage.value + 1 : 0;
};

onMounted(() => {
  props.images.map((path, index) => {
    const image = new Image();
    image.src = getApiUrl() + path;
    image.addEventListener('load', () => {
      imageSrc.value[index] = image.getAttribute('src') ?? '';
    });
  });
});
</script>

<style scoped lang="scss">
.images {
  @extend .flex-col;
  gap: 1rem;
  position: relative;
  height: max-content;
  padding: 0.75rem;
  background-color: #FFFFFFCC;
  border-radius: 20px;
  box-sizing: border-box;

  &__container, &__item {
    width: 53.5vw;
    height: 51dvh;
  }

  &__container {
    @extend .rounded;
    position: relative;
    min-width: 53.5vw;
    min-height: 51dvh;
    overflow: hidden;
  }

  &__item {
    object-fit: contain;
  }

  &__buttons {
    @extend .flex-row;
    justify-content: flex-end;
    gap: 1.75rem;

    &-item {
      @extend .circle, .pointer;
      @include icon($slide-arrow, 2.75rem, 2rem);
      background-position-x: 30%;
      background-color: $primary;

      &.next {
        transform: rotate(0.5turn);
      }
    }
  }
}

.mobile .images {
  height: 12rem;

  &__container, &__item{
    width: 100%;
    height: calc(12rem - 1.5rem);
  }

  &__container {
    min-width: 100%;
    min-height: calc(12rem - 1.5rem);
  }

  &__buttons {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    gap: 0.75rem;

    &-item {
      @include square(2.5rem);
      background-size: 1.75rem !important;
    }
  }
}

.slide {
  &-left, &-right {
    //general for all transitions
    &-leave, &-enter {
      &-from, &-to {
        position: absolute;
        left: 0;
        transition: all .5s ease, opacity .5s ease;
      }
    }

    // common for the animation end and start
    &-leave-to, &-enter-from {
      opacity: 0;
    }

    &-leave-from, &-enter-to {
      opacity: 1;
    }
  }

  &-left, &-right {
    &-leave-from, &-enter-to {
      left: 0;
      top: 0;
    }
  }
}

.slide-left-leave-to, .slide-right-enter-from {
  left: -100%;
}

.slide-left-enter-from, .slide-right-leave-to {
  left: 100%;
}

</style>

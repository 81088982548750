import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundView from "@/views/NotFoundView.vue";
import RoomView from "@/views/RoomView.vue";
import ReserveView from "@/views/ReserveView.vue";
import RulesView from "@/views/RulesView.vue";

const routes: Array<RouteRecordRaw> = [{
  path: '/:signature',
  children: [{
    path: '',
    name: 'home',
    component: HomeView
  }, {
    path: 'room/:roomTypeId',
    name: 'room',
    component: RoomView
  }, {
    path: 'room/:roomTypeId/reserve',
    name: 'reserve',
    component: ReserveView
  }, {
    path: 'rules',
    name: 'rules',
    component: RulesView
  }]
}, {
  path: '/:pathMatch(.*)*',
  name: 'not-found',
  component: NotFoundView
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router

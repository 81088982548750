<template>
  <div class="header">
    <project-logo />
    <div v-if="isMainPage" class="header__search">
      <div class="header__search-item" @click="isCalendarShown = true">
        <p class="header__search-item__title">{{ $t('check_in') }}</p>
        <p class="header__search-item__value">{{ moment(userData.dateFrom).format('DD.MM.YY') }}</p>
      </div>
      <div class="header__search-item" @click="isCalendarShown = true">
        <p class="header__search-item__title">{{ $t('check_out') }}</p>
        <p class="header__search-item__value">{{ moment(userData.dateTo).format('DD.MM.YY') }}</p>
      </div>
      <div class="header__search-item" id="guests-el" @click="isGuestsShown = true">
        <p class="header__search-item__title">
          {{ $t(screenType === 'mobile' ? 'guests_amount_mobile' : 'guests_amount') }}
        </p>
        <p class="header__search-item__value">{{ guests }}</p>
      </div>
      <div class="header__search-button" @click="onSearchClick"></div>
    </div>

    <form-select v-model="language" :options="languages" color="black" @update:model-value="onLanguageChange" />

    <a v-if="isMainPage || screenType === 'mobile'" href="https://chorna-skelya.com" target="_blank"
       rel="noopener noreferrer">
      <img :src="require(`@/assets/images/logos/${route.params.signature}.png`)" alt class="header__logo" />
    </a>
  </div>

  <form-custom-calendar v-if="isCalendarShown" :min="moment().format('YYYY-MM-DD')" :title="$t('choose_dates')"
                        :is-range="true" :initial-range="{ dateFrom: userData.dateFrom, dateTo: userData.dateTo }"
                        @close="isCalendarShown = false" @update:modelValue="onChange" />
  <guests-popup v-if="isGuestsShown" @close="isGuestsShown = false; onSearchClick();" />
  <error-popup v-if="isError" :error="error" @close="isError = false" />
</template>

<script setup lang="ts">
import ProjectLogo from "@/components/ProjectLogo.vue";
import { useRoute } from "vue-router";
import { computed, ref, watch } from "vue";
import FormCustomCalendar from "@/components/Form/FormCustomCalendar.vue";
import moment from "moment";
import { useOrganizationStore } from "@/stores/organizationStore";
import { storeToRefs } from "pinia";
import { FormPeriod, SelectOption } from "@/types/Form";
import GuestsPopup from "@/components/Popup/GuestsPopup.vue";
import ErrorPopup from "@/components/Popup/ErrorPopup.vue";
import { useRoomTypeStore } from "@/stores/roomTypeStore";
import { useI18n } from "vue-i18n";
import FormSelect from "@/components/Form/FormSelect.vue";

const emits = defineEmits(['set-loading-state']);

const i18n = useI18n();
const route = useRoute();
const organizationStore = useOrganizationStore();
const { userData, guests, error, screenType, organization } = storeToRefs(organizationStore);
const roomTypeStore = useRoomTypeStore();

const isCalendarShown = ref(false);
const isGuestsShown = ref(false);
const isError = ref(false);
const language = ref(i18n.locale.value);

const texts: { [key: string]: string } = {
  ua: 'УКР',
  en: 'ENG',
  ru: 'РУС',
  pl: 'PLN'
};

const isMainPage = computed((): boolean => route.name === 'home');

const languages = computed((): SelectOption[] => {
  const list: SelectOption[] = [];
  organization.value?.settings?.languages?.map(item => list.push({ value: item, label: texts[item] }));
  return list;
});

const onChange = (v: FormPeriod) => {
  userData.value.dateFrom = v.dateFrom;
  userData.value.dateTo = v.dateTo;
  isCalendarShown.value = false;
  onSearchClick();
};

const onLanguageChange = async (value: string) => {
  language.value = value;
  i18n.locale.value = value;
  localStorage.setItem('booking-lang', value);
};

const onSearchClick = async () => {
  emits('set-loading-state', true);
  await roomTypeStore.getFilteredRoomTypes();
  isError.value = !!error.value;
  emits('set-loading-state', false);
};
</script>

<style scoped lang="scss">
.header {
  @extend .row-container;
  justify-content: center;
  position: relative;
  min-height: 4rem;

  &__search {
    @extend .flex-row;
    gap: 30px;
    height: 4rem;
    padding: 0.75rem 0.75rem 0.75rem 30px;
    background-color: #FFFFFFCC;
    box-sizing: border-box;
    border-radius: 100px;

    &-item {
      @extend .flex-col, .pointer;
      gap: 0.25rem;

      &:nth-child(2) {
        padding: 0 30px;
        border-left: 1.5px solid #AEAAAA;
        border-right: 1.5px solid #AEAAAA;
      }

      &__title {
        @include font(10px, 120%, #83847F, 500);
      }

      &__value {
        @include font(20px, 120%, black, 500);
      }
    }

    &-button {
      @extend .circle, .pointer;
      margin-top: -2px;
      margin-right: -2px;
      @include icon($search, 2.75rem, 1.5rem);
      background-color: $primary !important;
    }
  }

  &__logo {
    @extend .rounded;
    position: absolute;
    top: 0;
    right: 0;
    @include square(clamp(80px, 11.7vw, 10rem));
    object-fit: cover;
  }

  .field {
    position: absolute;
    top: 0;
    width: 4.5rem;
  }

  :deep(.select) {
    @extend .row-container;
    max-height: 2rem;
    min-height: 2rem;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  :deep(.select__arrow) {
    top: 0.25rem;
    right: 0;
    filter: invert(1) brightness(1.5);
  }

  :deep(.select__value) {
    color: white;
  }

  :deep(.select__options) {
    min-width: 5rem;
    margin-top: 0.75rem;
    margin-left: -0.25rem;
    padding: 0;
    background-color: #0000001A;
    backdrop-filter: blur(2px);
  }

  :deep(.select__options-item__text) {
    @include font(20px, 24px, white, 600);
  }
}

.desk .header {
  margin-bottom: calc(min(2.5rem));

  .logo {
    width: calc(min(22vmin, 153px));
  }

  .field {
    right: 0.5rem;
  }

  &:has(.header__logo) {
    .field {
      right: calc(11.7vw + 1.5rem);
    }
  }
}

.mobile {
  .logo {
    width: calc(min(38vmin, 149px));
  }

  .header {
    &__logo {
      //@include square(80px);
    }

    &__search {
      position: relative;
      top: calc(80px + 1.5rem);
      gap: min(1rem, 3vw);
      justify-content: space-around;
      max-width: 100%;
      min-width: min(100%, 24rem);
      //min-width: 100%;
      padding: 0.75rem 1rem;

      &-item {
        gap: 4px;
        justify-content: space-between;

        &:nth-child(2) {
          padding: 0 1rem;
        }

        &__value {
          @include font(18px, 120%, black, 500);
        }
      }

      &-button {
        @include square(2.5rem);
      }
    }
  }

  .field {
    right: calc(80px + 1rem);
  }
}
</style>

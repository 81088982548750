<template>
  <div class="panel">
    <loader-panel v-if="isLoading" />
    <error-popup v-else-if="isDataError" :text="$t('user_data_error')" :button-text="$t('try_again')"
                 @close="push(getUrl(''))" />
    <div v-else class="panel__content">
      <p class="title title--back title--mobile" @click="push(getUrl('/'))">{{ getTranslation(roomType.name) }}</p>
      <div class="panel__main">
        <div class="panel__main-left">
          <p class="title title--back title--desktop" @click="push(getUrl('/'))">{{ getTranslation(roomType.name) }}</p>
          <div class="panel__main-left__container">
            <p v-html="getDetails(roomType)" class="panel__main-left__details"></p>

            <form-calendar-range :min="moment().format('YYYY-MM-DD')" :exclude="excludedDates"
                                 :initial="{ dateFrom: userData.dateFrom, dateTo: userData.dateTo }"
                                 @update:modelValue="onChange" />
            <form-guests-select />
            <form-animals-select />

            <p class="title">{{ $t('room.total') }}</p>
            <div class="panel__main-left__row">
              <p class="panel__main-left__text">
                {{ getTranslation(roomType.name) }},<br />{{ getCountableLocale('nights', duration) }}.
              </p>
              <p class="title">{{ price }} {{ $t(organization?.settings?.currency) }}</p>
            </div>
          </div>
        </div>
        <div class="panel__main-right">
          <images-panel :images="roomType.images" />
        </div>
      </div>
      <div class="panel__footer">
        <p class="panel__footer-link" @click="push(getUrl('/rules'))">
          {{ $t('room.rules_link') }} "{{ getTranslation(organization.name) }}"
        </p>
        <base-button :text="$t('continue')" @click="push(`${route.path}/reserve`)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useRoomTypeStore } from "@/stores/roomTypeStore";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useTranslation } from "@/composable/useTranslation";
import ImagesPanel from "@/components/Image/ImagesPanel.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useOrganizationStore } from "@/stores/organizationStore";
import FormCalendarRange from "@/components/Form/FormCalendarRange.vue";
import moment from "moment/moment";
import { FormPeriod } from "@/types/Form";
import FormGuestsSelect from "@/components/Form/FormGuestsSelect.vue";
import { useApi } from "@/composable/useApi";
import ErrorPopup from "@/components/Popup/ErrorPopup.vue";
import { useBookingStore } from "@/stores/bookingStore";
import FormAnimalsSelect from "@/components/Form/FormAnimalsSelect.vue";
import component from "*.vue";

const route = useRoute();
const { push } = useRouter();
const roomTypeStore = useRoomTypeStore();
const { roomType } = storeToRefs(roomTypeStore);
const organizationStore = useOrganizationStore();
const { organization, duration, userData } = storeToRefs(organizationStore);
const bookingStore = useBookingStore();
const { bookedSlots } = storeToRefs(bookingStore);
const { getTranslation, getDetails, getCountableLocale } = useTranslation();
const { checkData, getUrl } = useApi();

const excludedDates = ref<string[]>([]);
const isLoading = ref(true);
const isDataError = ref(false);

const price = computed((): number => {
  let price = roomType.value.price * duration.value;
  if (userData.value?.animals > 0) {
    price += duration.value * 500;
  }
  return price;
});

const onChange = (v: FormPeriod) => {
  userData.value.dateFrom = v.dateFrom;
  userData.value.dateTo = v.dateTo;
};

const setExcludedDates = () => {
  const dates: string[] = [];
  const fullSlots: string[][] = [];
  Object.values(bookedSlots.value).map((slots, index) => {
    fullSlots[index] = [];
    slots.map(slot => {
      const diff = moment(slot.dateTo).diff(slot.dateFrom, 'd');
      for (let i = 0; i < diff; i++) {
        const date = moment(slot.dateFrom).add(i, 'd').format('YYYY-MM-DD');
        dates.push(date);
        fullSlots[index].push(date);
      }
    });
  });

  dates.map(d => {
    if (fullSlots.find(s => !s.includes(d))) {
      return;
    } else {
      excludedDates.value.push(d);
    }
  });
}

onMounted(async () => {
  isDataError.value = checkData();
  if (!isDataError.value) {
    await roomTypeStore.getRoomType(+route.params.roomTypeId);
    await bookingStore.getRoomTypeBookings(+route.params.roomTypeId);
    setExcludedDates();
  }

  isLoading.value = false;
});
</script>

<style lang="scss" scoped>
.panel {
  &__content {
    @extend .flex-col;
    gap: 1.5rem;
    height: 100%;
  }

  &__main {
    @extend .flex-row;
    justify-content: space-between;
    gap: 4.75rem;
    max-height: calc(100% - 5rem);

    &-left {
      @extend .flex-col, .pointer;
      flex: 1;

      &__container {
        @extend .flex-col, .custom-scroll;
        gap: 1rem;
        padding-right: 0.5rem;
        overflow-y: auto;
      }

      &__details {
        //@extend .custom-scroll;
        //max-height: calc(100% - 21rem);
        margin-bottom: 0.5rem;
        @include font(18px, 120%, white, 500);
        //overflow-y: auto;
      }

      &__text {
        @include font(16px, 120%, white, 500);
      }

      &__row {
        @extend .flex-row;
        justify-content: space-between;
        gap: 1rem;

        .panel__main-left__name {
          min-width: max-content;
          text-transform: uppercase;
        }

        .title {
          text-align: center;
        }
      }
    }

    &-right {
      @extend .flex-col;
      gap: 1.5rem;
    }
  }

  &__footer {
    @extend .row-container;
    justify-content: space-between;
    margin-top: auto;

    &-link {
      @extend .pointer;
      position: relative;
      @include font(18px, 120%, white, 500);

      &:after {
        @include before(100%, 0);
        position: absolute;
        bottom: 1px;
        border-bottom: 1.5px solid white;
      }
    }
  }
}

.field + .title {
  margin-top: auto;
}

.field + .field {
  margin-top: 0.5rem;
}

.base-button {
  max-width: 32.75vw;
}

.mobile .panel {
  position: relative;
  max-height: calc(100% - 6rem - 56px - 1rem);
  margin-top: 2rem;

  .title--desktop {
    display: none;
  }

  &__content {
    gap: 1rem;
  }

  &__main {
    flex-direction: column-reverse;
    gap: 1rem;
    max-height: max-content;
    height: max-content;

    &-left {
      &__details {
        @include font(16px, 120%, white, 500);
      }

      &__row {
        //flex-direction: column;
      }
    }
  }

  &__footer {
    flex-direction: column;
    gap: 1rem;

    &-link {
      @include font(16px, 120%, white, 500);
      text-decoration: underline;

      &:after {
        display: none;
      }
    }

    .base-button {
      position: fixed;
      bottom: 1rem;
      width: calc(100% - 2rem);
      max-width: calc(100% - 2rem);
    }
  }
}

.desk .panel {
  &__main-left__container {
    margin-top: 1rem;
  }

  .title--mobile {
    display: none;
  }
}
</style>

<template>
  <div class="container" :class="screenType">
    <template v-if="!isLoading">
      <main-header v-if="!['/', '/login'].includes(route.path)" @set-loading-state="v => isContentLoading = v" />
      <router-view v-if="organization.id > 0 || route.name === 'not-found'" />
    </template>
    <loader-panel v-else />
    <loader-panel v-if="isContentLoading" class="inner" />
    <error-popup v-if="error && !organization.id" :text="error" @close="window.location.reload()" />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import MainHeader from "@/components/Header/MainHeader.vue";
import { storeToRefs } from "pinia";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useOrganizationStore } from "@/stores/organizationStore";
import ErrorPopup from "@/components/Popup/ErrorPopup.vue";

const route = useRoute();
const i18n = useI18n();
const organizationStore = useOrganizationStore();
const { organization, screenType, error } = storeToRefs(organizationStore);

const isLoading = ref(false);
const isContentLoading = ref(false);

const init = async () => {
  isLoading.value = true;
  console.log(route.params)

  if (route.params.signature !== undefined && organization.value?.signature !== route.params.signature) {
    await organizationStore.getOrganization(route.params.signature as string);
  }

  isLoading.value = false;
};

const setScreenType = () => screenType.value = document.documentElement.clientWidth >= 1000 ? 'desk' : 'mobile';

const getLang = (value: string) => {
  const langs: { [key: string]: string; } = {
    uk: 'ua',
    'uk-UA': 'ua',
    'ru-RU': 'ru',
    'en-US': 'en',
    'pl-PL': 'pl'
  };
  return langs?.[value] ?? value;
};

const setLanguage = () => {
  if (route.query?.lang) {
    i18n.locale.value = route.query.lang as string;
  } else {
    let lang = getLang(navigator.language || navigator?.userLanguage);

    const savedLang = localStorage.getItem('booking-lang');
    if (savedLang) {
      lang = savedLang;
    }

    i18n.locale.value = ['ua', 'en', 'ru', 'pl'].includes(lang) ? lang : 'en';
  }
};

onMounted(async () => {
  setLanguage();
  setScreenType();
  window.addEventListener('resize', setScreenType);

  const metaTheme = document.querySelector('meta[name=\'theme-color\']');
  if (metaTheme) {
    metaTheme.setAttribute('content', '#FFE500');
  }

  await init();

  console.log()
});

onBeforeUnmount(() => window.removeEventListener('resize', setScreenType));

watch(() => route.path, init);

watch(() => organization.value?.id, async () => {
  if (!organization.value?.id || organization.value.signature !== route.params.signature) {
    await init();
  }
});
</script>

<style lang="scss">
@import "src/assets/styles/base/core";

.container {
  background: url("@/assets/images/bg.jpg") center no-repeat;
  background-size: cover;

  &:after {
    @include before(100%, 100%);
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000099;
    backdrop-filter: blur(4px);
    z-index: -1;
  }
}

.container.desk {
  padding: calc(min(2.5rem, 5vmin)) calc(min(2.5rem, 5vmin));
}

.loader.inner {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #00000099;
}
</style>

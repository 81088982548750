<template>
  <div class='field' :class="`field--${color}`">
    <field-label :text="$t('guests_amount')" :is-required="true" />

    <div class='field__input select' ref='item' :class='selectClass' @click.stop='onClick'>
      <p v-if="selectedOptionLabel" v-html="selectedOptionLabel" class='select__value'></p>
      <div class='select__arrow' :class="{'select__arrow--turned': isActive}"></div>
    </div>

    <transition :name="`show-${direction}`">
      <div v-if='isActive' class='select__options' v-bind='optionsParams' @click.stop>
        <div class='select__options-list'>
          <div v-for="item in ['adults', 'children', 'infants']" :key="item" class="list__item">
            <div class="list__item-left">
              <p class="list__item-left__title">{{ $t(`guests.${ item }`) }}</p>
              <p class="list__item-left__subtitle">{{ $t(`guests.${ item }_age`) }}</p>
            </div>
            <div class="list__item-right">
              <div class="list__item-right__button minus" @click.stop="userData[item]--"
                   :class="{'disabled': userData[item] <= 0 || item === 'adults' && userData[item] <= 1}"></div>
              <p class="list__item-right__value">{{ userData[item] }}</p>
              <div class="list__item-right__button plus" :class="{'disabled': !canAddAmount}"
                   @click.stop="userData[item]++"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang='ts' setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { OptionParams } from "@/types/Form";
import { useFormItem } from "@/composable/useFormItem";
import { useOrganizationStore } from "@/stores/organizationStore";
import { storeToRefs } from "pinia";
import { useTranslation } from "@/composable/useTranslation";
import { useRoomTypeStore } from "@/stores/roomTypeStore";
import FieldLabel from "@/components/Form/FieldLabel.vue";

defineProps({
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
});
defineEmits(['update:modelValue']);

const organizationStore = useOrganizationStore();
const { userData } = storeToRefs(organizationStore);
const roomTypeStore = useRoomTypeStore();
const { roomType } = storeToRefs(roomTypeStore);
const { isActive, item, getFloatingPosition, setListeners, deleteListeners } = useFormItem();
const { getCountableLocale } = useTranslation();

const direction = ref('down');
const optionsParams = ref<OptionParams>({} as OptionParams);

const selectClass = computed((): { [key: string]: boolean } => {
  return {
    'select--active': isActive.value, // || !!props.selectedOption,
  };
});

const selectedOptionLabel = computed((): string => {
  const { adults, children, infants } = userData.value;
  const texts = [getCountableLocale('adults', adults)];
  if (children > 0) {
    texts.push(getCountableLocale('children', children));
  }
  if (infants > 0) {
    texts.push(getCountableLocale('infants', infants));
  }

  return texts.join(', ');
});

const canAddAmount = computed((): boolean => {
  const { adults, children, infants } = userData.value;
  return adults + children + infants < roomType.value.guests;
});

const onClick = () => {
  optionsParams.value = getFloatingPosition(3, 64);
  direction.value = optionsParams.value?.class ? optionsParams.value.class : direction.value;
  isActive.value = !isActive.value;
};

onMounted(setListeners);
onUnmounted(deleteListeners);
</script>

<style lang='scss' scoped>
@import "@/assets/styles/components/form/select";
@import "@/assets/styles/components/guests";

.select__options {
  padding: 1rem;
}

.list__item-right__button:not(.disabled) {
  border: 1px solid #83847F;
}
</style>

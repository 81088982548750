import { error } from "@/locales/pl/error";

export const pl = {
  message_404: 'Nie znaleziono strony',
  check_in: 'Zameldowanie',
  check_out: 'Wymeldowanie',
  guests_amount: 'Liczba gości',
  guests_amount_mobile: 'Gości',
  animals_amount: 'Ilość zwierząt',
  uah: 'UAH',
  usd: '$',
  continue: 'Kontynuuj',
  choose_date: 'Wybierz datę',
  choose_dates: 'Wybierz okres',
  available_date: 'Dostępne',
  unavailable_date: 'Niedostępne',
  user_data_error: 'Twoje dane są nieaktualne. Proszę, wyszukaj ponownie',
  try_again: 'Spróbuj ponownie',
  file_error: 'Błąd podczas przesyłania pliku',
  success_booking: 'Twoja prośba o rezerwację została pomyślnie przyjęta. Pracownik hotelu przetworzy ją w ciągu 24 godzin. Proszę, oczekuj na wiadomość e-mail, która zostanie wysłana na podany adres.',
  back: 'Wracać',
  select: 'Wybierz',

  home: {
    title: 'Opcje zakwaterowania dla Ciebie',
    more: 'Więcej ofert',
    details: 'Szczegóły rezerwacji',
  },

  room: {
    rules_link: 'Zasady pobytu dla gości hotelowych',
    total: 'Łącznie',
  },

  reserve: {
    form_title: 'Aby zarezerwować pokój, wypełnij formularz',
    name: 'Imię i Nazwisko',
    email: 'Email',
    phone: 'Numer telefonu',
    arrival: 'Szacowany czas przyjazdu',
    details_title: 'Szczegóły rezerwacji',
    room: 'Pokój',
    dates: 'Daty',
    guests: 'Liczba gości',
    conditions_title: 'Warunki płatności za rezerwację',
    conditions_text: 'Aby pomyślnie zarezerwować pokój, wymagana jest przedpłata w wysokości 50% całkowitej kwoty. Po dokonaniu płatności prosimy o dołączenie zdjęcia lub zrzutu ekranu pokwitowania w poniższym polu.',
    payment_title: 'Dane do przelewu',
    recipient: 'Odbiorca',
    edrpou: 'Kod EDRPOU',
    iban: 'IBAN',
    bank: 'Bank odbiorcy',
    mfo: 'MFO banku',
    purpose: 'Cel płatności',
    amount: 'Kwota',
    add_photo: 'Dodaj plik (PNG, JPG, JPEG, PDF)',
    reserve: 'Zarezerwuj',
    animals: 'Zwierzęta',
    yes: 'Tak',
    no: 'Nie',
  },

  guests: {
    title: 'Wybierz gości:',
    adults: 'Dorośli',
    adults_age: 'Wiek od 13 lat',
    children: 'Dzieci',
    children_age: 'Wiek 2 - 12 lat',
    infants: 'Niemowlęta',
    infants_age: 'Wiek 0 - 2 lat',
    animals: 'Zwierzęta',
    animals_age: 'do 5 kg · 500 uah / dzień',
  },

  for_days: {
    one: 'w {value} dzień',
    two: 'w {value} dni',
    five: 'w {value} dni',
  },

  found_variants: {
    one: 'Znaleziono {value} opcję',
    two: 'Znaleziono {value} opcje',
    five: 'Znaleziono {value} opcji',
  },

  nights: {
    one: '{value} noc',
    two: '{value} noce',
    five: '{value} nocy',
  },

  adults: {
    one: '{value} Dorosły',
    two: '{value} Dorosłych',
    five: '{value} Dorosłych',
  },

  children: {
    one: '{value} Dziecko',
    two: '{value} Dzieci',
    five: '{value} Dzieci',
  },

  infants: {
    one: '{value} Niemowlę',
    two: '{value} Niemowląt',
    five: '{value} Niemowląt',
  },

  people: {
    one: '{value} Osoba',
    two: '{value} Osoby',
    five: '{value} Osób',
  },

  animals: {
    one: '{value} Zwierzę',
    two: '{value} Zwierzęta',
    five: '{value} Zwierzęta',
  },

  error,
};

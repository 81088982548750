<template>
  <div class="panel">
    <loader-panel v-if="isLoading" />
    <div v-else class="panel__content">
      <p class="title">{{ $t('home.title') }}:</p>
      <p class="panel__content-subtitle">({{ getCountableLocale('found_variants', roomTypes.length) }})</p>
      <div class="list" ref="list">
        <div v-for="type in currentTypes" :key="type.id" class="list__item" @click="push(getUrl(`/room/${type.id}`))">
          <div class="list__item-left">
            <p class="list__item-left__name">{{ getTranslation(type.name) }}</p>
            <p class="list__item-left__price">
              {{ type.price * duration }} {{ $t(organization?.settings?.currency) }}
              {{ getCountableLocale('for_days', duration) }}
            </p>
            <p v-html="getDetails(type)" class="list__item-left__details"></p>
            <div class="panel__content-button" @click="push(getUrl(`/room/${type.id}`))">
              {{ $t('home.details') }}
            </div>
          </div>
          <async-image class="list__item-right" :src="images?.[type.id]" />
        </div>
        <p v-if="!currentTypes.length" class="empty">{{ $t('home.not_found') }}</p>
      </div>
      <div class="panel__content-button" :class="{'disabled': limit > roomTypes.length}" @click="onMoreClick">
        {{ $t('home.more') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoomTypeStore } from "@/stores/roomTypeStore";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useTranslation } from "@/composable/useTranslation";
import { RoomType } from "@/types/RoomType";
import { useApi } from "@/composable/useApi";
import AsyncImage from "@/components/Image/AsyncImage.vue";
import { useRouter } from "vue-router";
import { useOrganizationStore } from "@/stores/organizationStore";

const { push } = useRouter();
const roomTypeStore = useRoomTypeStore();
const { roomTypes } = storeToRefs(roomTypeStore);
const organizationStore = useOrganizationStore();
const { organization, duration } = storeToRefs(organizationStore);
const { getTranslation, getDetails, getCountableLocale } = useTranslation();
const { getApiUrl, getUrl } = useApi();

const images = ref<{ [key: number]: string }>({});
const limit = ref(4);
const list = ref();
const isLoading = ref(true);

const currentTypes = computed((): RoomType[] => roomTypes.value.slice(0, limit.value));

const onMoreClick = () => {
  limit.value += 4;
  setTimeout(() => list.value.scrollTo({ top: list.value.clientHeight * limit.value, behavior: 'smooth' }), 50);
};

onMounted(async () => {
  await roomTypeStore.getRoomTypes();
  roomTypes.value.map(type => {
    const image = new Image();
    image.src = getApiUrl() + type.images[type.mainImage];
    image.addEventListener('load', () => {
      images.value[type.id] = image.getAttribute('src') ?? '';
    });
  });

  isLoading.value = false;
});
</script>

<style lang="scss" scoped>
.panel__content {
  @extend .flex-col;
  height: 100%;

  &-subtitle {
    margin-top: -1rem;
    @include font(12px, 120%, white, 600);
  }

  &-button {
    @extend .pointer;
    min-height: 2.5rem;
    width: max-content;
    min-width: 22.5vw;
    margin: auto;
    @include font(16px, 2.5rem, black, 500, center);
    background-color: $primary;
    border-radius: 7px;
    transition-duration: 0.2s;
  }

  .list__item-name {
    @extend .clip-line--2;
    height: 2.75rem;
    @include font(18px, 120%, black, 500);
  }

  .list__item-price {
    @include font(16px, 120%, #444343, 500);
  }

  .list {
    @extend .flex-row, .custom-scroll;
    gap: 1.5rem;
    flex-wrap: wrap;
    height: 100%;
    padding-bottom: 2px;
    overflow-y: auto;

    &__item {
      @extend .flex-row, .rounded, .pointer;
      justify-content: space-between;
      gap: 1rem;
      width: calc(50% - 0.75rem);
      height: calc(50% - 0.75rem);
      min-height: calc(50% - 0.75rem);
      //max-height: calc(50% - 0.75rem);
      //max-height: 14rem;
      padding: 1rem;
      background-color: #FFFFFFCC;
      backdrop-filter: blur(8px);
      box-sizing: border-box;

      &-left {
        @extend .flex-col;
        gap: 0.5rem;
        max-width: 50%;

        &__name {
          @include font(18px, 120%, black, 500);
        }

        &__price {
          @include font(16px, 120%, #444343, 500);
        }

        &__details {
          position: relative;
          max-height: 100%;
          overflow: hidden;
          @include font(18px, 120%, black, 500);
          background: -webkit-linear-gradient(black 40%, #ccc);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &-right {
        flex: 1;
        width: 19.375vw;
        min-width: 19.375vw;
        height: 100%;
      }

      .panel__content-button {
        margin-bottom: 0;
        min-width: 100%;
      }
    }
  }
}

.mobile .panel {
  margin-top: calc(80px + 42px);

  &__content {
    gap: 1rem;

    &-subtitle {
      margin-top: -0.75rem;
    }

    &-button {
      min-height: 56px;
      width: 100%;
      margin: 0 auto;
      line-height: 56px;
    }
  }

  .list {
    gap: 1rem;
    max-height: calc(100% - 10rem - 56px);

    &:has(.empty) {
      max-height: calc(100% - 56px);
    }

    &__item {
      width: 100%;
      height: max-content;
      padding: 0.75rem;

      &-left {
        max-width: 44.7vw;

        &__name {
          @extend .clip-line--2;
          min-height: 2.25rem;
          @include font(min(18px, 4vw), 120%, black, 500);
        }

        &__price {
          @include font(12px, 120%, #444343, 500);
        }

        &__details {
          max-height: calc(34vmin - 2.25rem - 1rem - 1rem);
          @include font(min(14px, 3.25vw), 120%, black, 500);
        }
      }

      &-right {
        min-width: 38.7vw;
        width: 38.7vw;
        height: unset;
        max-height: 34vmin;
      }

      .panel__content-button {
        display: none;
      }
    }
  }
}
</style>
